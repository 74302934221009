<template lang="pug">
div(:data-product='data.id' data-test='product-item' :class='{ "product--disabled": data.active === false }' class='product')
  icon(v-if='data.pConInformation' name='sliders' class='product__pconIcon')
  div(class='product__imageContainer')
    router-link(:to='route' class='product__image')
      img(v-image-lazy :data-src='data.image' data-cloudinary='f_auto/t_md' class='aspect-ratio-[1.25]')
    div(class='product__buttons')
      slot
  div(class='product__text')
    div
      div(class='product__title')
        router-link(:to='route')
          span(v-html='data.title')
      router-link(v-if='data.brand && routeBrand' class='product__brand link-light' :to='routeBrand' :replace='$route.name === "products"')
        span(v-html='data.brand.title')
    div(v-if='data.retailPriceNet' class='product__price')
      div
        div(class='text-xs text-text-light')
          | {{ $t('UVP net') }}
        span
          | {{ $t('from') }} !{ ' ' }
          b {{ data.retailPriceNet }}
          a(class='link-light' href='#priceInfo') &nbsp;*
</template>

<script lang="ts">
import type { IProductSearchItem } from '@/types'
import { defineComponent, computed, type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

const ProductItem = defineComponent({
  props: {
    data: { type: Object as PropType<IProductSearchItem>, required: true },
  },
  setup(props) {
    return {
      route: computed<RouteLocationRaw>(() => ({ name: '/products/[id]', params: { id: props.data.id } })),
      routeBrand: computed<RouteLocationRaw | undefined>(() =>
        props.data.brand.id ? { name: '/products/', query: { brands: [props.data.brand.id] } } : undefined,
      ),
    }
  },
})

export default ProductItem
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.product
  position: relative
  display: block
  flex-flow: column
  padding: 0!important
  &__pconIcon
    position: absolute
    top: 10px
    right: 10px
    z-index: 99
    color: var(--color-text)
  &__editButton
    display: none !important
    position: absolute !important
    top: 10px
    right: 10px
    ~/:hover &
      display: block !important
  &__buttons
    position: absolute !important
    bottom: 8px
    right: 10px
    display: grid
    grid-gap: 8px
    grid-template-columns: auto
    grid-auto-flow: column
  &__title, &__price
    margin-top: 10px !important
    font-family: $font-lato
    font-size: 15px
    line-height: 18px
  &__text
    display: flex
  &__price
    padding-left: 10px
    flex-grow: 1
    justify-content: flex-end
    align-items: flex-end
    display: flex
    white-space: nowrap
    text-align: right
  &__title
    margin-top: 10px !important
    display: flex
    font-weight: bold
    justify-content: space-between
    a
      color: var(--grey-900)
      font-family: $font-lato
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      display: -webkit-box
      overflow: hidden
      -webkit-font-smoothing: antialiased
  &__meta
    margin-top: 2px !important
  &__brand
    margin-top: 0 !important
    font-size: 15px
  &__materials
    font-size: 13px
    margin-top: 5px
  &__imageContainer
    width: 100%
    background: #fff
    padding-top: 80%
    position: relative
    border-radius: $border-radius
    ~/--disabled &
      &:before
        content: 'disabled'
        position: absolute
        top: 0
        left: 0
        padding: 2px 6px
        font-size: 12px
        text-transform: uppercase
        display: block
        background: var(--red-600)
        color: #fff
  &__image
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: 20px
    ~/--disabled &
      opacity: 0.25
    img
      width: 100%
      height: 100%
      object-fit: contain
      object-position: center
</style>
