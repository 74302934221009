<template lang="pug">
div(class='dotnav')
  button(v-for='option in num' :key='option' :class='{ "dotnav__button--active": modelValue === option - 1 }' :title='`${option}`' class='dotnav__button' @click='activate(option - 1)')
</template>
<script lang="ts">
import { defineComponent, type SetupContext } from 'vue'

const DotNav = defineComponent({
  props: {
    modelValue: [String, Number],
    num: Number,
  },
  setup(props, context: SetupContext) {
    return {
      activate: (option: string | number) => context.emit('update:modelValue', option),
    }
  },
})
export default DotNav
</script>
<style lang="stylus">
@import '../styles/variables.styl'
.dotnav
  display: flex
  flex-flow: row
  &__button
    background: transparent
    border: none
    width: 24px
    height: 24px
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    &:before
      content: ''
      display: block
      width: 5px
      height: 5px
      background: var(--grey-800)
      opacity: 0.25
      border-radius: 5px
    &&:hover:before
      opacity: 0.5
    &&--active:before
      opacity: 1
</style>
