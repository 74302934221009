<template lang="pug">
div(class='video-player')
  button(:class='{ "video-player__button--hidden": playing }' class='video-player__button' @click='playing = !playing')
    icon(name='play')
  video(ref='video')
</template>

<script lang="ts">
import { useMediaControls } from '@vueuse/core'
import { defineComponent, onMounted, ref } from 'vue'

const VideoPlayer = defineComponent({
  props: { src: String, autoplay: { default: false, type: Boolean } },
  setup(props) {
    const video = ref()
    const { playing } = useMediaControls(video as any, {
      src: props.src,
    })
    onMounted(() => props.autoplay && setTimeout(() => (playing.value = true), 700))
    return { video, playing }
  },
})

export default VideoPlayer
</script>
<style lang="stylus">
@import '../styles/variables.styl'
:root
 --video-border-radius: 6px
.video-player
  position: relative
  aspect-ratio: 380 / 220
  display: flex
  video
  video, button
    border-radius: var(--video-border-radius)
    width: 100%
    margin: 0!important
  &__button
    position: absolute
    border: none
    font-size: 30px
    cursor: pointer
    background: rgba($color-manatee, 0.2)
    z-index: 2
    &:hover
      background: rgba($color-manatee, 0.3)
    left: 0
    top: 0
    height: 100%
    opacity: 1
    transition: opacity .15s ease
    &&--hidden
      opacity: 0
</style>
